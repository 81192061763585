import { Link } from "gatsby";
import React from "react";
import { Container } from "../styles/UI";
import { navigate } from "gatsby";
import Layout from "../components/Layout";
import Seo from "../components/Seo";
import styled from "styled-components";

import taxcelBgLogo from "../images/home/logo-negative.svg";
import Button from "../DesignSystem/Button";

const Hero = styled.section`
  width: 100%;
  background: ${(p) => p.theme.degradeAzul};
  margin-top: -180px;
  padding-top: 180px;
  padding-bottom: 120px;
  color: var(--color-white);
  box-sizing: border-box;
  overflow: hidden;

  .buttons {
    button {
      margin-top: 16px;
    }
    @media (min-width: ${(p) => p.theme.bp.tablet}) {
      display: flex;
      align-items: center;

      button:first-child {
        margin-right: 24px;
      }
    }
  }

  button:last-child:hover {
    background: var(--color-white);
  }

  button:last-child {
    box-shadow: 0px 8px 16px -4px #05185b;
  }

  ${Container} {
    .cta {
      position: relative;
      z-index: 10;

      h1 {
        font-size: 30px;
        font-weight: 600;
        letter-spacing: -0.01em;
        margin-bottom: 24px;
        line-height: 1.25;
      }

      p {
        font-weight: 350;
        font-size: 20px;
        line-height: 32px;
        margin-bottom: 24px;
      }
    }

    .illustration {
      z-index: 1;
      margin-top: 0px;
      position: relative;

      .bg-logo {
        position: absolute;
        z-index: 0;
        height: 800px;
        right: -180px;
        top: -240px;

        @media (min-width: ${(p) => p.theme.bp.tablet}) {
          left: -100px;
          top: -580px;
          height: 1360px;
        }
      }
    }

    @media (min-width: ${(p) => p.theme.bp.tablet}) {
      max-height: 820px;
      display: grid;
      /* grid-template-columns: repeat(2, 1fr); */
      grid-template-columns: minmax(600px, 1fr) 1fr;
      grid-column-gap: 32px;
      align-items: center;

      h1 {
        font-size: 35px;
        margin-top: 64px;
      }
    }
  }
`;

const Politica = styled.div`
  padding: 80px 40px;

  h1 {
    margin-bottom: 80px;
    font-weight: 600;
    font-size: 36px;
    line-height: 56px;
    /* or 123% */

    text-align: center;
    letter-spacing: -1px;
    background: ${(p) => p.theme.degradeMisto2};
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  @media (min-width: ${(p) => p.theme.bp.tablet}) {
    line-height: 42px;

    span {
      font-size: 50px;
    }
  }

  h2 {
    margin-top: 1.5em;
    margin-bottom: 16px;
    font-weight: 700;
    font-size: 1.75rem;
    color: ${(p) => p.theme.primary};
    letter-spacing: -0.025em;
  }

  h3 {
    margin-top: 1em;
    margin-bottom: 16px;
    font-weight: 700;
    font-size: 1.25rem;
    color: ${(p) => p.theme.primary};
    letter-spacing: -0.025em;
  }

  p {
    margin-bottom: 1rem;
    line-height: 1.3;
    text-align: justify;
  }

  ul {
    list-style-type: disc;
    list-style-position: inside;
    margin-left: 1.25rem;

    li {
      margin-bottom: 1rem;
      line-height: 1.4;
    }
  }

  ol {
    list-style-type: lower-roman;
    list-style-position: inside;
    margin-left: 1.25rem;

    li {
      margin-bottom: 1rem;
      line-height: 1.4;
    }
  }
`;

export default function PoliticaPrivacidade() {
  const handleClick = () => {
    navigate("/");
  };
  const handleClickBack = () => {
    navigate(-1);
  };

  return (
    <Layout>
      <Seo title="Política de Privacidade" />
      <Hero>
        <Container>
          <div className="cta">
            <h1>Política de Privacidade</h1>
            <p>
              Qualquer dúvida, solicitação ou reclamação em relação à nossa
              política de privacidade pode ser esclarecida entrando em contato
              conosco, por meio do e-mail: lgpd@taxcel.com.br ou por telefone:
              (11) 5039-4214.
            </p>
            <p>
              <b>Última Atualização:</b> agosto de 2023
            </p>
            <div className="buttons">
              <Button
                ghost
                bg="secondary"
                color="secondary"
                onClick={handleClickBack}
              >
                Voltar
              </Button>
              <Button bg="secondary" color="primary" onClick={handleClick}>
                Ir para a Página Inicial
              </Button>
            </div>
          </div>
          <div className="illustration">
            <img src={taxcelBgLogo} alt="" className="bg-logo" />
          </div>
        </Container>
      </Hero>
      <Politica>
        <Container>
          <h1>
            <span>Política de Privacidade</span>
          </h1>
          <p>
            A TAXCEL SOLUÇÕES FISCAIS S.A., uma sociedade anônima devidamente
            constituída de acordo com as Leis do Brasil, com sede na Cidade de
            São Paulo, Estado de São Paulo, na Avenida Brigadeiro Faria Lima,
            1755, Bairro Jardim Paulistano, CEP: 01452-001 (“Taxcel” ou
            “Empresa”) está comprometida com o objetivo de resguardar a
            privacidade de seus clientes e usuários de sua plataforma. Neste
            documento, são esclarecidas as formas pelas quais as informações de
            seus usuários são coletadas, armazenadas e utilizadas nos sites{" "}
            <a href="http://www.taxcel.com.br">www.taxcel.com.br</a>,{" "}
            <a href="http://blog.taxceladdins.com.br">
              blog.taxceladdins.com.br
            </a>
            , e respectivos serviços.
          </p>
          <p>
            Para os fins desta política de privacidade, usuário e clientes são
            as pessoas naturais ou jurídicas que de qualquer forma interajam com
            os serviços e/ou produtos fornecidos pela Taxcel, incluindo, mas não
            se limitando às pessoas que solicitem orçamento no site, utilizem
            qualquer plataforma ou software da Taxcel, adquiram produtos ou
            serviços fornecidos pela Taxcel, atendam eventos ou palestras
            organizados pela Taxcel e/ou recebam qualquer material elaborado
            pela Taxcel.
          </p>
          <p>
            Para o desenvolvimento de suas atividades, a Taxcel coleta e
            armazena diversos dados e informações de seus usuários por meio de
            seu site e aplicativo, principalmente visando proporcionar a melhor
            experiência possível aos usuários.
          </p>
          <p>
            Ao utilizar nossos serviços, você entende que coletaremos e usaremos
            suas informações pessoais nas formas descritas nesta Política, sob
            as normas da Lei Geral de Proteção de Dados (LGPD, Lei Federal
            13.709/2018), e as demais normas do ordenamento jurídico brasileiro
            aplicáveis.
          </p>
          <p>
            Esta Política pode ser alterada periodicamente, conforme informado
            ao final do documento.
          </p>

          <h2>1. QUEM SOMOS</h2>
          <p>
            Somos uma TaxTech que simplifica o dia a dia de quem trabalha com
            tributos no Brasil. Nosso foco é transformar as rotinas dos
            departamentos fiscais através de tecnologias que servem às pessoas.
            Elevamos a performance dos times fiscais das empresas com produtos
            disruptivos que aumentam a produtividade, reduzem custos e apoiam
            uma cultura de decisões data driven. Dessa forma nós possibilitamos
            que o profissional fiscal desloque o seu foco de trabalho dos
            serviços operacionais passando a participar de tomadas de decisão
            estratégicas para o negócio. Assim, nós potencializamos a sua
            evolução e apoiamos a sua jornada de transformação digital (Tax
            Transformation).
          </p>

          <h2>2. O QUE VOCÊ PRECISA SABER ANTES DE LER ESSE DOCUMENTO?</h2>
          <p>
            <b>Dicionário de Definições:</b>
          </p>
          <p>
            <b>Dados Pessoais:</b> são todas as informações que permitem a sua
            identificação. Como por exemplo, seu nome, CPF, e-mail, telefone,
            entre outros.
          </p>
          <p>
            <b>Dados Pessoais Sensíveis:</b> são dados pessoais relacionados à
            origem racial ou étnica, convicção religiosa, opinião política,
            filiação a sindicato ou a organização de caráter religioso,
            filosófico ou político, dado referente à saúde ou à vida sexual,
            dado genético ou biométrico, quando vinculados a Você.
          </p>
          <p>
            <b>Encarregado pelo Tratamento de Dados Pessoais:</b> também
            conhecido como Data Protection Officer ou DPO, é o profissional
            responsável por atuar como canal de comunicação entre a Taxcel, os
            Titulares e a Autoridade Nacional de Proteção de Dados (ANPD). Um
            dos seus objetivos é garantir que a Taxcel esteja em conformidade
            com as leis e normas relativas à privacidade e proteção de Dados
            Pessoais.
          </p>
          <p>
            <b>Titular:</b> é a pessoa física a quem se referem os Dados
            Pessoais.
          </p>
          <p>
            <b>Tratamento de Dados Pessoais:</b> significa qualquer operação,
            como a coleta, produção, recepção, classificação, utilização,
            acesso, reprodução, transmissão, distribuição, processamento,
            arquivamento, armazenamento, eliminação, avaliação ou controle da
            informação, modificação, comunicação, transferência, difusão ou
            extração, realizada com dados pessoais.
          </p>

          <h2>3. COLETA E ARMAZENAMENTO DE DADOS PESSOAIS</h2>
          <p>
            Para construirmos e mantermos uma relação de confiança, é importante
            que tenha ciência sobre como tratamos os seus Dados Pessoais, e,
            principalmente, sobre como, onde e quais são os dados que coletamos,
            conforme informado a seguir:
          </p>
          <p>
            <u>Informações fornecidas por clientes ou usuários.</u> A Taxcel
            coleta e armazena os seguintes dados pessoais não sensíveis: (a)
            nome; (b) telefone; (c) e-mail; (d) empresa em que trabalha; e (e)
            cargo – via preenchimento dos formulários para download de conteúdos
            gratuitos da Empresa. Eventualmente, a solicitação de algumas
            informações pode ser feita por meio de contato direto com os
            usuários via e-mail ou telefone.
          </p>
          <p>
            <u>Informações de navegação no site.</u> Quando os clientes ou
            usuários visitam os sites da Taxcel, são inseridos ‘cookies’ em seus
            navegadores por meio do software Google Analytics. Isso é feito para
            fins de identificação da quantidade de vezes que cada cliente ou
            usuário retorna ao endereço da Empresa. São coletadas informações
            como endereço IP, localização geográfica, fonte de referência, tipo
            de navegador, duração da visita e páginas visitadas.
          </p>
          <p>
            <u>Histórico de contato.</u> A Taxcel armazena informações a
            respeito de todos os contatos já realizados com seus clientes e
            usuários, como conteúdos baixados a partir de nossas páginas e
            interações via e-mail.
          </p>
          <p>
            <u>Dados Pessoais não Coletados ou Armazenados.</u> A Taxcel não
            coleta, armazena ou processa nenhum dado pessoal de natureza
            sensível, como por exemplo: origem racial ou étnica, convicção
            religiosa, opinião política, filiação a sindicato ou a organização
            de caráter religioso, filosófico ou político, dado referente à saúde
            ou à vida sexual, dado genético ou biométrico.
          </p>
          <h2>4. PARA QUAIS FINALIDADES UTILIZAMOS OS DADOS PESSOAIS?</h2>
          <p>
            A Taxcel coleta dados para a prestação de serviços da Empresa,
            principalmente, para que seja possível viabilizar a relação
            comercial, cumprir algumas obrigações contratuais e legais, bem como
            engajarmos a nossa marca.
          </p>
          <p>
            A Taxcel utilizará os dados e informações de seus clientes e
            usuários para as seguintes finalidades:
          </p>
          <ul>
            <li>
              <b>Execução de contrato</b>
              <ul>
                <li>
                  Utilização dos dados, a fim de assegurar a nossa prestação de
                  serviços. Os dados do cartão de crédito que forem cadastrados
                  poderão ser utilizados com a finalidade exclusiva do pagamento
                  dos serviços (pontual ou periodicamente), quando assim
                  indicar.
                </li>
              </ul>
            </li>
            <li>
              <b>Comunicações direcionadas</b>
              <ul>
                <li>
                  Utilização do e-mail para envio de material ou informação
                  requisitada pelo cliente ou usuário no preenchimento do
                  formulário. Também poderá ser usado para o envio de
                  Newsletters, relacionadas aos temas de atenção da Taxcel. O
                  e-mail será utilizado, ainda, como veículo de informação a
                  respeito de lançamento de novos materiais ou ferramentas da
                  Taxcel e, eventualmente, seus parceiros. No entanto, o cliente
                  ou usuário poderá cancelar sua assinatura a qualquer momento;
                </li>
                <li>
                  Funcionários da Taxcel poderão eventualmente entrar em contato
                  via e-mail ou telefone para fazer pesquisas ou apresentar
                  produtos e serviços;
                </li>
              </ul>
            </li>
            <li>
              <b>Aprimoramento da experiência do usuário</b>
              <ul>
                <li>
                  Os dados de download poderão ser divulgados como pesquisas e
                  estatísticas, não sendo reveladas abertamente nenhuma
                  informação pessoal, a menos que autorizada explicitamente;
                </li>
                <li>
                  Envio aos clientes e usuários de mensagens a respeito de
                  suporte ou serviço, como alertas, notificações e atualizações;
                </li>
              </ul>
            </li>
            <li>
              <b>Cumprimento de obrigações legais</b>
              <ul>
                <li>
                  A Taxcel se reserva no direito de monitorar toda a plataforma,
                  principalmente para assegurar que as regras descritas em
                  eventual contrato firmado entre as partes estão sendo
                  observadas, ou se não há violação ou abuso das leis
                  aplicáveis. A Taxcel se reserva, ainda, no direito de excluir
                  qualquer cliente ou usuário, independentemente do tipo que
                  for, caso a presente Política de Privacidade não seja
                  respeitada.
                </li>
              </ul>
            </li>
            <li>
              <b>Marketing mediante consentimento</b>
              <ul>
                <li>
                  Após obtenção do consentimento dos usuários e clientes, a
                  Taxcel poderá utilizar os dados para fins publicitários e/ou
                  de marketing. Algumas das maneiras pelas quais a Taxcel poderá
                  utilizar os dados para fins publicitários são: (i) divulgação
                  de produtos e serviços em redes sociais; (ii) envio de
                  comunicações aos clientes e usuários a respeito de produtos,
                  serviços, promoções, notícias, atualizações, eventos e outros
                  assuntos que possam ser do interesse dos clientes e usuários;
                  (iii) divulgação de produtos e serviços de parceiros
                  comerciais, dentre outros.
                </li>
              </ul>
            </li>
          </ul>
          <p>
            Para preservar a privacidade de seus clientes e usuários, a Taxcel
            trata com confidencialidade todos os dados e informações coletados,
            sendo estes somente usados para os fins aqui descritos e autorizados
            por seus clientes e usuários. A Taxcel garante que coleta a menor
            quantidade de dados necessários para a prestação dos serviços e que
            somente usará os dados dos usuários e clientes para os fins
            descritos nessa política.
          </p>
          <p>
            Eventualmente, a Taxcel poderá utilizar os dados coletados para
            finalidades não previstas nesta Política de Privacidade, mas a
            referida utilização será para atendimento dos interesses legítimos
            da Taxcel e estará dentro das legítimas expectativas dos usuários e
            clientes, nos termos da Lei 13.709/2018. O eventual uso dos dados e
            informações de seus clientes e usuários para finalidades que não
            cumpram com essa prerrogativa somente será feito com autorização
            prévia do usuário.
          </p>

          <h2>5. COM QUEM COMPARTILHAMOS OS DADOS PESSOAIS?</h2>
          <p>
            Apenas os funcionários da Taxcel terão acesso aos dados de seus
            clientes e usuários. Todos os dados e informações de clientes e
            usuários são confidenciais e qualquer uso destes estará de acordo
            com a presente Política. A Taxcel empreenderá todos os esforços
            razoáveis de mercado para garantir a segurança dos nossos sistemas e
            dos dados de seus clientes e usuários.
          </p>
          <p>
            Todas as informações de clientes e usuários serão, sempre que
            possível, criptografadas, caso não inviabilizem o seu uso pela
            Taxcel.
          </p>
          <p>
            A Taxcel considera a sua privacidade algo extremamente importante e
            fará tudo que estiver ao seu alcance para protegê-la. Todavia, não é
            possível garantir que todos os dados e informações sobre seus
            clientes e usuários da plataforma Taxcel estarão livres de acessos
            não autorizados, principalmente caso haja compartilhamento indevido
            das credenciais necessárias para acessar a referida plataforma.
            Assim, cada cliente e usuário é o único responsável por manter sua
            senha de acesso em local seguro, sendo vedado o compartilhamento
            desta com terceiros. Os clientes e usuários se comprometem a
            notificar a Taxcel, imediatamente, por meio seguro, caso haja
            qualquer uso não autorizado de sua conta, bem como o acesso não
            autorizado por terceiros a esta.
          </p>
          <p>
            Ao clicar nos botões de compartilhamento de conteúdo nas redes
            sociais disponíveis em nossas páginas, o usuário publicará o
            conteúdo por meio de seu perfil na rede selecionada. A Taxcel não
            tem acesso ao login e senha dos usuários nessas redes, nem publicará
            conteúdo em nome do usuário sem que ele realize esta ação.
          </p>
          <p>
            A Taxcel compartilha os dados dos usuários com terceiros quando
            estritamente necessário para prestar os serviços da Empresa e/ou
            para melhorar a qualidade dos serviços prestados. Alguns exemplos do
            compartilhamento de dados para prestação dos serviços são: (a)
            compartilhamento de dados para processamento de pagamentos; (b)
            envio de e-mails com informações sobre atualizações nos serviços;
            (c) realização de auditoria interna conduzida por auditores
            independentes.
          </p>
          <p>
            Para fornecer e aprimorar nossos serviços, podemos compartilhar seus
            dados pessoais com empresas parceiras que atuam como
            sub-processadores. Essas empresas são cuidadosamente selecionadas e
            contratualmente obrigadas a proteger seus dados, utilizando-os
            apenas conforme nossas instruções e em conformidade com esta
            Política de Privacidade e a legislação aplicável.
          </p>
          <p>Atualmente, utilizamos os seguintes sub-processadores:</p>
          <ul>
            <li>
              <b>Microsoft Azure:</b> Para serviços de computação em nuvem,
              incluindo armazenamento de dados (Azure Blob Storage) e banco de
              dados (Azure SQL Server).
            </li>
            <li>
              <b>MongoDB:</b> Para serviços de banco de dados.
            </li>
            <li>
              <b>Conta Azul:</b> Para processamento de pagamentos.
            </li>
          </ul>
          <p>
            A lista completa e atualizada de nossos sub-processadores, incluindo
            detalhes sobre os serviços prestados e as medidas de segurança
            adotadas, está disponível em nosso website.
          </p>
          <p>
            A Taxcel pedirá o consentimento expresso dos usuários e clientes
            para compartilhar dados sem que haja um legítimo interesse
            comercial, sempre respeitando os direitos e liberdades fundamentais
            do usuário.
          </p>
          <p>
            A Taxcel pode vir a receber dados de terceiros para melhorar a
            qualidade dos serviços prestados aos usuários e clientes. A Taxcel
            também poderá receber dados que a ajudem a melhor identificar
            potenciais clientes interessados em adquirir seus produtos e
            serviços e/ou produtos e serviços de parceiros comerciais.
          </p>
          <p>
            A Taxcel se reserva no direito de fornecer dados e informações de
            seus clientes e usuários, incluindo interações, caso seja
            requisitada judicialmente para tanto, ato necessário para que a
            Empresa esteja em conformidade com as leis nacionais, ou caso haja
            autorização expressa por parte do cliente ou usuário.
          </p>

          <h2>6. ARMAZENAMENTO DE DADOS PESSOAIS</h2>
          <p>
            A Empresa manterá os dados e informações somente até quando estas
            forem necessárias ou relevantes para as finalidades descritas nesta
            Política, ou em caso de períodos pré-determinados por lei, ou até
            quando estas forem necessárias para a manutenção de interesses
            legítimos da Taxcel.
          </p>
          <p>
            A qualquer momento, os clientes e usuários poderão requisitar cópia
            dos seus dados armazenados nos sistemas da Taxcel.
          </p>
          <p>
            Caso queira realizar esta solicitação, basta entrar em contato
            conosco por e-mail ou telefone, disponíveis em nosso site e
            informados ao final deste documento.
          </p>

          <h2>
            7. CANCELAMENTO DA ASSINATURA E ALTERAÇÃO/EXCLUSÃO DE INFORMAÇÕES
            PESSOAIS
          </h2>
          <p>
            Cada cliente ou usuário poderá optar por não mais receber qualquer
            tipo de e-mail da Taxcel. Em suas comunicações via e-mail, há sempre
            um link para cancelar a assinatura, ao final do corpo do e-mail.
            Clicando nesse link, é possível realizar o descadastramento da
            lista.
          </p>
          <p>
            Caso haja novo preenchimento de qualquer formulário por parte de
            cliente ou usuário, fica caracterizada a reinserção de seu e-mail à
            lista de contatos ativos da Taxcel. Assim, uma nova requisição de
            cancelamento deverá ser feita nessa hipótese.
          </p>
          <p>
            Os dados coletados de cada cliente ou usuário poderão ser excluídos
            da base da Empresa quando houver expresso requerimento nesse
            sentido, ou quando não forem mais necessários nem relevantes às
            atividades da Taxcel, salvo se houver qualquer outra razão para sua
            manutenção, como eventual obrigação legal de retenção de dados,
            necessidade de preservação destes para resguardo de direitos da
            Taxcel ou necessidade de utilização dos dados para prestar os
            serviços ao usuário.
          </p>
          <p>
            Se você gostaria de gerenciar, alterar ou excluir seus Dados
            Pessoais, você pode fazê-lo, entrando em contato conosco por e-mail
            ou telefone, disponíveis em nosso site e informados ao final deste
            documento.
          </p>

          <h2>8. OUTROS DIREITOS DOS USUÁRIOS/TITULARES DOS DADOS</h2>
          <p>
            Os usuários e clientes terão os seguintes direitos em relação aos
            seus próprios dados, observados os limites legais:
          </p>
          <ul>
            <li>Confirmação da existência de tratamento de dados;</li>
            <li>Acesso aos dados pessoais;</li>
            <li>Correção de dados incompletos, inexatos ou desatualizados;</li>
            <li>
              Anonimização, bloqueio ou eliminação de dados desnecessários,
              excessivos ou tratados em desconformidade com a legislação
              brasileira;
            </li>
            <li>
              Portabilidade dos dados brutos a outro fornecedor de serviço ou
              produto, mediante requisição expressa e observados os limites
              aplicáveis;
            </li>
            <li>
              Eliminação dos dados pessoais tratados com o consentimento do
              titular, observadas as exceções legais e os dados necessários para
              que os serviços sejam prestados;
            </li>
            <li>
              Obter informação das entidades públicas e privadas com as quais o
              controlador realizou uso compartilhado de dados;
            </li>
            <li>
              Negar o consentimento para o compartilhamento de dados nas
              situações aplicáveis;
            </li>
            <li>Revogação de seu consentimento previamente dado.</li>
          </ul>
          <p>
            Para atender às requisições dos titulares, podemos solicitar uma
            cópia de seu documento de identificação. Isto é necessário para
            confirmar e validar sua identidade, em razão da existência de
            pessoas potencialmente mal-intencionadas ou, ainda, para situações
            de homônimos.
          </p>
          <p>
            Além disso, as requisições que envolvam Dados Pessoais e/ou
            documentos de outros Titulares não serão atendidas, exceto mediante
            procuração, poder parental ou outra hipótese que autorize o
            exercício do direito de outro Titular por você. Nas situações em que
            não possuir mais acesso ao e-mail cadastrado em nossas plataformas,
            também poderemos solicitar uma cópia do seu documento de
            identificação para recuperação do login e senha.
          </p>
          <p>
            Em circunstâncias específicas, é possível que sua requisição não
            seja atendida. Nestes casos, explicaremos os motivos que
            justificaram o não atendimento.
          </p>
          <p>
            Para exercer qualquer dos direitos citados acima, basta entrar em
            contato conosco por e-mail ou telefone, disponíveis em nosso site e
            informados ao final deste documento.
          </p>

          <h2>9. DO ACESSO AOS DADOS NAS FERRAMENTAS QUE OFERECEMOS</h2>
          <ul>
            <li>
              <b>TaxSheets</b>
              <p>
                TaxSheets é uma solução on-premises, desta forma os dados
                inseridos ficam localmente na máquina do usuário, não há
                compartilhamento externo e/ou qualquer compartilhamento de dados
                pessoais com a Taxcel ou terceiros, desta forma, todos os dados
                e conteúdos inseridos no Software pelos usuários, são de única e
                exclusiva responsabilidade deste.
              </p>
              <p>
                A Taxcel não tem acesso a nenhuma informação fiscal ou contábil
                inserida pelo usuário no Software, toda e qualquer declaração de
                cunho contábil ou fiscal será de única e exclusiva
                responsabilidade da parte declarante.
              </p>
              <p>
                Caberá exclusivamente ao cliente responsabilizar-se pela
                adequação, conformidade e cumprimento da legislação no tocante à
                autorização do tratamento dos dados pessoais disponibilizados
                pelo cliente no Software da Taxcel. Em relação a estes dados, a
                Taxcel se enquadra como Operadora de dados.
              </p>
            </li>
            <li>
              <b>TaxDashs</b>
              <p>
                TaxDashs é uma solução BI para Tax. São inseridos nesta
                ferramenta dados fiscais e contábeis que ficam armazenados em
                provedor Cloud da Microsoft Azure.
              </p>
              <p>
                A Taxcel apenas poderá utilizar eventuais dados pessoais
                inseridos na ferramenta para conseguir prestar o serviço
                contratado e para melhoria da própria ferramenta. A Taxcel não
                utilizará os dados pessoais inseridos na solução para qualquer
                outra finalidade, sem o prévio consentimento do usuário.
              </p>
              <p>
                Todos os dados e conteúdos inseridos no Software pelos usuários
                do cliente, são de única e exclusiva responsabilidade do
                cliente.
              </p>
              <p>
                Caberá exclusivamente ao usuário responsabilizar-se pela
                adequação, conformidade e cumprimento da legislação no tocante à
                autorização do tratamento dos dados pessoais disponibilizados
                pelo cliente no Software da Taxcel. Em relação a estes dados, a
                Taxcel se enquadra como Operadora de dados.
              </p>
            </li>
          </ul>

          <h2>10. CONFIDENCIALIDADE E USO LEGÍTIMO DOS DADOS</h2>
          <p>
            A Taxcel declara e garante que sempre agiu e sempre agirá em
            conformidade com os princípios da boa-fé e da lealdade em relação
            aos dados e informações fornecidas por seus usuários e clientes,
            conforme definido pela Lei 9.279/96, sendo certo que em nenhuma
            hipótese a Taxcel utilizará os dados do usuário para obter vantagem
            injusta ou desleal ou de qualquer forma se beneficiar da utilização
            predatória ou escusa de tais dados.
          </p>

          <h2>11. MUDANÇAS NA POLÍTICA DE PRIVACIDADE</h2>
          <p>
            Se mudarmos a forma de tratar os dados pessoais, a presente Política
            de Privacidade poderá passar por atualizações. Assim, é recomendável
            que cada cliente ou usuário visite periodicamente a correspondente
            página do site da Taxcel, a fim de se manter atualizado.
          </p>
          <p>
            Toda e qualquer alteração visa a se adequar às eventuais
            modificações em nossos Sites, sejam de mudanças para novas
            tecnologias ou sempre que for necessário, bem como a novos
            requisitos legais, regulatórios ou contratuais.
          </p>
          <p>
            Caso não concorde com as alterações incluídas no documento, você tem
            direito de solicitar o cancelamento de seu cadastro a qualquer
            tempo.
          </p>

          <h2>12. LEI APLICÁVEL</h2>
          <p>
            Este documento é regido e deve ser interpretado de acordo com as
            leis da República Federativa do Brasil. Fica eleito o Foro da
            Comarca de São Paulo/SP, como o competente para dirimir quaisquer
            questões porventura oriundas do presente documento, com expressa
            renúncia a qualquer outro, por mais privilegiado que seja.
          </p>

          <h2>13. CONTATO</h2>
          <p>
            Qualquer dúvida, solicitação ou reclamação em relação à nossa
            política de privacidade pode ser esclarecida entrando em contato
            conosco, por meio do e-mail:{" "}
            <a href="mailto:lgpd@taxcel.com.br">lgpd@taxcel.com.br</a> ou por
            telefone: (11) 5039-4214.
          </p>
          <p>
            <u>ATUALIZADO EM JULHO DE 2024</u>
          </p>
        </Container>
      </Politica>
    </Layout>
  );
}
